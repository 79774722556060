<template>
  <div v-if="roughnessIsLoaded">
    <Modal :title="{
      ru: 'Добавление шероховатости для всей детали',
      en: 'Add roughness to the entire part',
    }" :show="showInfoAllModal" @ok="closeModal('showInfoAllModal')" :okText="{
      ru: 'Ок',
      en: 'OK',
    }" :showCancel="false" @cancel="closeModal('showInfoAllModal')">
      <template slot="body">
        <div>{{ tech.all_info_modal[language] }}</div>
      </template>
    </Modal>
    <Modal :title="{
      ru: 'Добавление шероховатости для выбранных поверхностей',
      en: 'Add Roughness to Selected Surfaces',
    }" :show="showInfoModal"  @ok="closeModal('showInfoModal')" :okText="{
      ru: 'Ок',
      en: 'OK',
    }" :showCancel="false" @cancel="closeModal('showInfoModal')">
      <template slot="body">
        <div>{{ tech.info_modal[language] }}</div>
      </template>
    </Modal>
    <Modal :title="{
      ru: 'Удаление группы поверхностей',
      en: 'Delete a surface group',
    }" :show="showDeleteModal"  @ok="deleteGroup(deleteIndex)" :okText="{
      ru: 'Удалить',
      en: 'Delete',
    }" @cancel="closeModal('showDeleteModal')">
      <template slot="body">
        <div>{{ tech.delete_modal[language] }}</div>
      </template>
    </Modal>
    <div class="tech-req__title">
      {{ tech.r_all_r[language] }}
    </div>
    <div class="tech-req__card">
      <div
        v-if="allRoughnessColor"
        class="tech-req__card_color-line"
        :style="{
          backgroundColor: `rgb(${
          allRoughnessColor.r * 255},${allRoughnessColor.g * 255},${allRoughnessColor.b * 255})`}">
      </div>
      <div style="width: 100%">
        <div class="tech-req__card_container">
          <div class="tech-req__card_title block block__between">
            <div>{{ tech.r_add_all_r[language] }}</div>
            <div>
              <Info @click="openModal('showInfoAllModal')" />
            </div>
          </div>
          <div class="block block__between block__align-center" v-if="allRoughnessEdit && showEdit">
            <div class="block__flex">
              <div class="block block__align-center" :style="{maxWidth: '100px'}">
                <Select
                  name="material_gost1"
                  :defaultValue="capitalizeFirstLetter(activeRoughnessType)"
                  :values="[
                  {
                    title: 'Ra',
                  },
                  {
                    title: 'Rz',
                  }
                ]"
                  :dropdown-props="{
                    label: 'title',
                    value: 'title',
                  }"
                  @change="setRoughnessType($event)"
                  class="tech-req__card_select" />
              </div>
              <div class="block" :style="{maxWidth: '100px', marginLeft: '10px'}">
                <Select
                  v-show="activeRoughnessType === 'ra'"
                  name="roughness_type"
                  :values="roughnessRa"
                  :defaultValue="allRaRoughnessValue"
                  :dropdown-props="{
                    label: 'short_name',
                    value: 'id',
                  }"
                  :style="{width: '100%'}"
                  @change="setAllRoughnessValue($event, 'ra')"
                  class="tech-req__card_select" />
                <Select
                  v-show="activeRoughnessType === 'rz'"
                  name="roughness_type"
                  :values="roughnessRz"
                  :defaultValue="allRzRoughnessValue"
                  @change="setAllRoughnessValue($event, 'rz')"
                  :dropdown-props="{
                    label: 'short_name',
                    value: 'id',
                  }"
                  :style="{width: '100%'}"
                  class="tech-req__card_select" />
              </div>
            </div>
            <a-button size="small" type="primary" @click="saveAllRoughness">
              {{ tech.r_save[language] }}
            </a-button>
          </div>
          <div
            v-if="!allRoughnessEdit || !showEdit"
            class="block block__between block__align-center">
            <div class="tech-req__card_notactive_value" @click="() => {
              if (showEdit) {
                this.allRoughnessEdit = true;
              }
        }">
              {{ `${capitalizeFirstLetter(activeRoughnessType)} ${
              activeRoughnessType === 'ra' ? allRaRoughnessValue : allRzRoughnessValue}` }}
            </div>
            <div
              v-if="showEdit"
              class="tech-req__card_notactive_change" @click="() => {
          this.allRoughnessEdit = true;
        }">
              {{ tech.r_change[language] }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="tech-req__left_params_title">
      {{ tech.r_surface_r[language] }}
    </div>
    <div v-if="roughness && roughness.groups">
      <div v-for="(group, index) in roughness.groups" v-bind:key="`group_${index}`">
        <div
          :class="`block tech-req__card ${
          index === activeRoughness ? 'tech-req__card_active' : ''}`"
          style="align-items: stretch">
          <div
            v-if="group.color"
            class="tech-req__card_color-line"
            :style="{
          backgroundColor: `rgb(${
          group.color.r * 255},${group.color.g * 255},${group.color.b * 255})`}">
          </div>
          <div style="width: 100%">
            <div
              class="block block__between block__align-center"
              style="padding: 15px 15px 7px 15px">
              <div class="tech-req__card_title block block__between">
                <div class="block__flex block__align-bottom">
                  <div>{{ tech.r_surface_count[language] }}</div>
                  <div class="tech-req__card_count">
                    {{group.surfaces.length}}
                  </div>
                </div>
                <div class="block__flex">
                  <Info @click="openModal('showInfoModal')" />
                  <Close
                    v-if="showEdit"
                    @click="openDeleteModal(index)" :style="{marginLeft: '7px'}" />
                </div>
              </div>
            </div>
            <div class="block block__between block__align-center"
                 style="padding: 7px 15px 15px 15px"
                 v-if="index === activeRoughness && showEdit">
              <div class="block__flex">
                <div class="block" :style="{maxWidth: '100px'}">
                  <Select
                    name="material_gost1"
                    @change="setSurfaceRoughnessType($event, index)"
                    :defaultValue="group.roughness_type === 0 ? 'Ra' : 'Rz'"
                    :values="[
                    {
                      title: 'Ra',
                    },
                    {
                      title: 'Rz',
                    }
                  ]"
                    :dropdown-props="{
                    label: 'title',
                    value: 'title',
                  }"
                    class="tech-req__card_select" />
                </div>
                <div class="block" :style="{maxWidth: '100px', marginLeft: '10px'}">
                  <Select
                    v-show="group.roughness_type === 0"
                    name="roughness_type"
                    :values="roughnessRa"
                    :dropdown-props="{
                    label: 'short_name',
                    value: 'id',
                  }"
                    :defaultValue="group.value"
                    @change="setSurfaceRoughness($event, index)"
                    :style="{width: '100%'}"
                    class="tech-req__card_select" />
                  <Select
                    v-show="group.roughness_type === 1"
                    name="roughness_type"
                    :values="roughnessRz"
                    :dropdown-props="{
                    label: 'short_name',
                    value: 'id',
                  }"
                    :defaultValue="group.value"
                    @change="setSurfaceRoughness($event, index)"
                    :style="{width: '100%'}"
                    class="tech-req__card_select" />
                </div>
              </div>
              <a-button size="small" @click="saveRoughness()" type="primary"
                        :disabled="group.surfaces.length === 0">
                {{ tech.r_save[language] }}
              </a-button>
            </div>
            <div
              v-if="index !== activeRoughness || !showEdit"
              style="padding: 15px 7px 15px 15px"
              class="block block__between block__align-center">
              <div class="tech-req__card_notactive_value"
                   v-if="group.value"
                   @click="changeRoughnessGroup(index)">
                {{ `${capitalizeFirstLetter(
                group.roughness_type === 0
                  ? 'Ra'
                  : 'Rz'
              )} ${group.value}` }}
              </div>
              <div class="tech-req__card_notactive_change"
                   v-if="!group.value"
                   @click="changeRoughnessGroup(index)">
                {{ tech.r_r_not_set[language] }}
              </div>
              <div
                v-if="showEdit"
                class="tech-req__card_notactive_change" @click="changeRoughnessGroup(index)">
                {{ tech.r_change[language] }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div :class="`tech-req__card tech-req__card_plus`" v-if="!allRoughnessEdit && showEdit">
      <div v-if="activeAdd" @click="addRoughness">
        <div class="block block__center">
          <Plus />
        </div>
        <div class="block block__center">
          <div class="tech-req__card_plus_text">
            {{ tech.r_surface_add[language] }}
          </div>
        </div>
      </div>
      <div v-if="!activeAdd">
        <div class="block block__center block__column block__align-center">
          <div class="tech-req__card_plus_text">
            {{ tech.r_surface_select[language] }}
          </div>
          <div class="tech-req__card_plus_text tech-req__card_plus_cancel"
               @click="cancelAddRoughness">
            {{ tech.r_cancel[language] }}
          </div>
        </div>
      </div>
    </div>
    <div :class="`tech-req__card`" v-if="allRoughnessEdit && showEdit">
      <div class="block block__center block__column block__align-center">
        <div class="tech-req__card_plus_text">
          {{ tech.r_for_add_surface[language] }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { query } from '@/utilites/axios';
import tech from '@/assets/text/techV2.json';

const Info = () => import('@/assets/pictures/tech/info.svg');
const Select = () => import('@/components/select/Select.vue');
const Modal = () => import('@/components/modal/Modal.vue');
const Plus = () => import('@/assets/pictures/tech/plus.svg');
const Close = () => import('@/assets/pictures/tech/close.svg');

export default {
  name: 'RoughnessV2',
  data() {
    return {
      tech,
      showEdit: true,
      unsubscribe: () => {},
      modelIsLoaded: false,
      roughnessIsLoaded: false,
      roughness: null,
      roughnessRa: null,
      roughnessRz: null,
      roughnessGroups: [],
      flatRoughness: {},
      clickColor: { r: 0.674, g: 0.074, b: 0.145 },
      hideColor: { r: 0.7529, g: 0.7529, b: 0.7529 },
      activeGroup: [],
      features: null,
      processedFeatures: [],
      flatFeatures: {},
      kteClick: false,
      activeClick: false,
      showInfoAllModal: false,
      showInfoModal: false,
      showDeleteModal: false,
      allRoughnessEdit: true,
      activeRoughnessType: 'ra',
      allRaRoughnessValue: '12.5',
      allRzRoughnessValue: '50',
      allRaRoughnessId: 10,
      allRzRoughnessId: 16,
      showPlus: false,
      partDataRoughness: null,
      activeAdd: true,
      activeRoughness: null,
      deleteIndex: null,
      allRoughnessColor: null,
    };
  },
  props: {
    partData: {
      type: Object,
    },
    edit: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    partData: function (val) {
      this.partDataRoughness = val;
    },
    activeRoughness: function () {
      this.setFlatRoughness();
    },
    edit: function (val) {
      this.showEdit = val;
    },
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
  },
  methods: {
    getFeatures() {
      query('post', 'getFeaturesByPart', {
        time_stamp: this.partData.time_stamp,
        hash: this.partData.hash,
        file_name: this.partData.file_name,
      }, {
        token: localStorage.getItem('token'),
      }).then((result) => {
        this.features = result.features_result;
        for (const [index, feature] of result.features_result.entries()) {
          const result = [];
          if (feature.faces) {
            for (const item of feature.faces) {
              this.flatFeatures[`Face${item}`] = index;
              result.push(`Face${item}`);
            }
          }
          this.processedFeatures.push(result);
        }
      });
    },
    getRoughness() {
      return new Promise((resolve) => {
        query('post', 'getTechDataByPartOrder', {
          roughness: true,
          uuid: this.$route.params.uuid,
        }, {
          token: localStorage.getItem('token'),
        }).then((result) => {
          this.roughness = result.roughness;
          if (result.roughness && result.roughness.groups) {
            this.roughnessGroups = result.roughness.groups;
            for (const [index, group] of result.roughness.groups.entries()) {
              for (const surface of group.surfaces) {
                this.flatRoughness[surface] = index;
              }
            }
          }
          resolve();
        });
      });
    },
    getRoughnessRa() {
      return new Promise((resolve) => {
        query('get', 'getRoughnessRaShort', {}, {})
          .then((item) => {
            this.roughnessRa = item;
            resolve();
          });
      });
    },
    getRoughnessRz() {
      return new Promise((resolve) => {
        query('get', 'getRoughnessRzShort', {}, {})
          .then((item) => {
            this.roughnessRz = item;
            resolve();
          });
      });
    },
    surfaceClicked(name) {
      if (this.activeClick) {
        const removeFromGroup = (face) => {
          const groups = this.roughnessGroups.slice();
          const editableGroup = groups[this.flatRoughness[face]].surfaces;
          const index = editableGroup.findIndex((el) => el === face);
          editableGroup.splice(index, 1);
          this.roughnessGroups = groups;
        };
        const addInGroup = (face) => {
          const groups = this.roughnessGroups.slice();
          const editableGroup = groups[this.flatRoughness[face]].surfaces;
          editableGroup.push(face);
          this.roughnessGroups = groups;
        };
        const index = this.activeGroup.findIndex((el) => el === name);
        if (index >= 0) {
          if (this.kteClick) {
            for (const face of this.processedFeatures[this.flatFeatures[name]]) {
              const faceIndex = this.activeGroup.findIndex((el) => el === face);
              if (this.flatRoughness[face] && this.flatRoughness[face] !== this.activeRoughness) {
                addInGroup(face);
              }
              if (faceIndex >= 0) {
                this.activeGroup.splice(faceIndex, 1);
              }
            }
          } else {
            if ((this.flatRoughness[name] || this.flatRoughness[name] === 0)
              && (this.flatRoughness[name] !== this.activeRoughness)) {
              addInGroup(name);
            }
            this.activeGroup.splice(index, 1);
          }
        } else {
          if (this.kteClick) {
            this.activeGroup = [
              ...this.activeGroup,
              ...this.processedFeatures[this.flatFeatures[name]],
            ];
            if (this.flatRoughness[name] && this.flatRoughness[name] !== this.activeRoughness) {
              for (const face of this.processedFeatures[this.flatFeatures[name]]) {
                removeFromGroup(face);
              }
            }
          } else {
            if ((this.flatRoughness[name] || this.flatRoughness[name] === 0)
              && (this.flatRoughness[name] !== this.activeRoughness)) {
              removeFromGroup(name);
            }
            this.activeGroup.push(name);
          }
        }
        const groups = this.roughnessGroups.slice();
        groups[this.activeRoughness].surfaces = this.activeGroup;
        this.roughnessGroups = groups;
        const result = [
          {
            surfaces: this.activeGroup,
            color: this.clickColor,
          },
        ];
        this.$store.state.modelV2.decorateSurfaces(result);
      }
    },
    colorGroups() {
      if (this.roughnessGroups) {
        const groups = this.roughnessGroups.slice();
        if (this.roughness && this.roughness.all) {
          groups.push({
            ...this.roughness.all,
            ...{
              surfaces: 'all',
            },
          });
        }
        for (const [i, group] of groups.entries()) {
          if (group.roughness_type === 0) { // ra
            const index = this.roughnessRa.findIndex((el) => el.id === group.roughness_id);
            groups[i].value = +this.roughnessRa[index].short_name;
          } else if (group.roughness_type === 1) { // rz
            const index = this.roughnessRz.findIndex((el) => el.id === group.roughness_id);
            groups[i].value = +this.roughnessRz[index].short_name;
          }
        }
        groups.sort((a, b) => b.value - a.value);
        groups.forEach((value, index) => {
          let percent = 0;
          if (groups.length > 1) {
            percent = (1 / (groups.length - 1)) * index;
          }
          const color = this.setColor(percent);
          if (value.surfaces === 'all') {
            this.allRoughnessColor = color;
          }
          groups[index].color = color;
        });
        this.$store.state.modelV2.decorateSurfaces(groups);
        this.$store.state.modelV2.decorateViewer(groups);
      }
    },
    setFlatRoughness() {
      for (const [index, group] of this.roughnessGroups.entries()) {
        for (const surface of group.surfaces) {
          this.flatRoughness[surface] = index;
        }
      }
    },
    setColor(percent) {
      const zero = 0.5;
      const one = 0.89;
      const change = one - zero;
      const summ = one + zero;
      const color = {
        r: 0,
        g: 0,
        b: 0,
      };
      if (percent >= 0 && percent <= 0.25) {
        color.r = zero;
        color.g = +(zero + ((percent / 0.25) * change)).toFixed(3);
        color.b = one;
      } else if (percent > 0.25 && percent <= 0.5) {
        color.r = zero;
        color.g = one;
        color.b = +(summ - (zero + ((percent / 0.5) * change))).toFixed(3);
      } else if (percent > 0.5 && percent <= 0.75) {
        color.r = +(zero + ((percent / 0.75) * change)).toFixed(3);
        color.g = one;
        color.b = zero;
      } else {
        color.r = one;
        color.g = +(summ - (zero + ((percent) * change))).toFixed(3);
        color.b = zero;
      }
      return color;
    },
    openModal(modalName) {
      this[modalName] = true;
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    setRoughnessType(item) {
      if (item.value.toLowerCase() === 'ra') {
        this.allRaRoughnessValue = '12.5';
      } else {
        this.allRzRoughnessValue = '50';
      }
      this.activeRoughnessType = item.value.toLowerCase();
    },
    setAllRoughnessValue(data, type) {
      if (type === 'ra') {
        this.allRaRoughnessValue = data.label;
        this.allRaRoughnessId = data.value;
      } else {
        this.allRzRoughnessValue = data.label;
        this.allRzRoughnessId = data.value;
      }
    },
    saveAllRoughness() {
      this.allRoughnessEdit = false;
      this.showPlus = true;
      this.saveData();
      // Тут сохранение и разукрашка
    },
    closeModal(modalName) {
      this[modalName] = false;
    },
    saveData() {
      const setType = (type) => {
        if (type === 'ra') {
          return 0;
        } else {
          return 1;
        }
      };
      const groups = this.roughnessGroups;
      const roughness = {
        all: {
          roughness_type: setType(this.activeRoughnessType),
          roughness_id: this.activeRoughnessType === 'ra'
            ? this.allRaRoughnessId : this.allRzRoughnessId,
        },
        groups,
      };
      query('post', 'changeTechDataByPartOrder', {
        hash: this.partDataRoughness.hash,
        time_stamp: this.partDataRoughness.time_stamp,
        file_name: this.partDataRoughness.file_name,
        uuid: this.$route.params.uuid,
        roughness,
      }, {
        token: localStorage.getItem('token'),
      }).then(() => {
        this.getRoughness();
      });
    },
    addRoughness() {
      this.activeAdd = false;
      this.activeClick = true;
      this.roughnessGroups.push(
        {
          roughness_id: 10,
          roughness_type: 0,
          value: 12.5,
          surfaces: [],
        },
      );
      this.$store.state.modelV2.clearSurfaces();
      this.activeGroup = [];
      this.activeRoughness = this.roughnessGroups.length - 1;
    },
    cancelAddRoughness() {
      this.activeAdd = true;
      this.colorGroups();
    },
    openDeleteModal(index) {
      this.deleteIndex = index;
      this.showDeleteModal = true;
    },
    setSurfaceRoughnessType(value, index) {
      // const previous_type = this.roughnessGroups[index].roughness_type;
      this.roughnessGroups[index].roughness_type = value.value;
    },
    setSurfaceRoughness(value, index) {
      this.roughnessGroups[index].roughness_id = value.value;
    },
    saveRoughness() {
      const roughnessId = this.roughnessGroups[this.activeRoughness].roughness_id;
      const roughnessType = this.roughnessGroups[this.activeRoughness].roughness_type;
      for (const [index, group] of this.roughnessGroups.entries()) {
        if (index !== this.activeRoughness
          && roughnessId === group.roughness_id
          && roughnessType === group.roughness_type) {
          this.roughnessGroups[this.activeRoughness].surfaces = [
            ...this.roughnessGroups[this.activeRoughness].surfaces,
            ...group.surfaces,
          ];
          this.roughnessGroups.splice(index, 1);
        }
        if (group.surfaces.length === 0) {
          this.roughnessGroups.splice(index, 1);
        }
      }
      this.activeClick = false;
      this.activeGroup = [];
      this.activeRoughness = null;
      this.activeAdd = true;
      this.colorGroups();
      this.saveData();
    },
    changeRoughnessGroup(index) {
      if (this.showEdit) {
        this.activeAdd = false;
        this.activeRoughness = index;
        this.activeClick = true;
        this.activeGroup = this.roughnessGroups[index].surfaces;
        const surfaces = [
          {
            surfaces: this.roughnessGroups[index].surfaces,
            color: this.clickColor,
          },
        ];
        this.$store.state.modelV2.decorateSurfaces(surfaces);
      }
    },
    deleteGroup(index) {
      this.roughnessGroups.splice(index, 1);
      this.saveData();
      this.colorGroups();
      this.activeClick = false;
      this.setFlatRoughness();
      this.activeRoughness = null;
      this.showDeleteModal = false;
      if (this.activeRoughness === null) {
        this.activeAdd = true;
      }
    },
  },
  mounted() {
    this.showEdit = this.edit;
    this.$store.state.modelV2.changeSelectorType('component');
    this.partDataRoughness = this.partData;
    this.$store.commit('modelV2/setSurfaceClick', this.surfaceClicked);
    this.modelIsLoaded = this.$store.state.modelV2.modelReady;
    Promise.all([
      this.getRoughness(),
      this.getRoughnessRa(),
      this.getRoughnessRz(),
    ]).then(() => {
      if (this.modelIsLoaded) {
        this.colorGroups();
      }
      if (this.roughness && this.roughness.all) {
        if (this.roughness.all.roughness_type === 0) {
          const allRoughness = this.roughnessRa
            .find((item) => item.id === this.roughness.all.roughness_id);
          this.allRaRoughnessValue = allRoughness.short_name;
          this.allRaRoughnessId = this.roughness.all.roughness_id;
        } else {
          const allRoughness = this.roughnessRz
            .find((item) => item.id === this.roughness.all.roughness_id);
          this.allRzRoughnessValue = allRoughness.short_name;
          this.allRzRoughnessId = this.roughness.all.roughness_id;
        }
        this.allRoughnessEdit = false;
      }
      this.roughnessIsLoaded = true;
    });
    this.unsubscribe = this.$store.subscribe((mutation) => {
      if (mutation.type === 'modelV2/setModelReady') {
        this.modelIsLoaded = mutation.payload;
        if (this.roughnessIsLoaded) {
          this.colorGroups();
        }
      }
    });
    // this.getFeatures();
  },
  beforeDestroy() {
    this.unsubscribe();
    this.$store.state.modelV2.decorateViewer(null);
    this.$store.state.modelV2.clearSurfaces();
  },
  components: {
    Info,
    Select,
    Modal,
    Plus,
    Close,
  },
};
</script>
